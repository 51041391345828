import React, { useContext, useEffect, useState } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import "./Transaction.css";
import axios from "axios";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import moment from "moment";
import { FaEye } from "react-icons/fa";
import { Pagination } from "@mui/material";
import ViewTransactionsModal from "./ViewTransactionModal";
import { useNavigate } from "react-router-dom";

const Transaction = () => {
  const [transactionDetails, setTransactionDetails] = useState([]);
  const { AIuser } = useContext(UserAuthContext);
  const navigate = useNavigate();
  const [isChecking, setIschecking] = useState(false);
  console.log(AIuser?._id);

  const itemsPerPage = 12; // Number of items to display per page

  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Calculate the start and end indices of the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's data
  const paginatedData = transactionDetails?.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    // scroll to half of the page
    window.scrollTo(0, window.innerHeight / 3);
    setCurrentPage(page);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const getTransaction = async () => {
      setIschecking(true);
      const response = await axios.get(
        `https://backend.whalesai.com/api/v1/credits-transaction/get/${AIuser?._id}`
      );
      console.log(response?.data?.data, "all the data");
      setTransactionDetails(response?.data?.data?.reverse());

      setIschecking(false);
    };
    getTransaction();
  }, [AIuser?._id]);

  console.log("Trans....===>", transactionDetails);

  const handleRedirectToView = (details) => {
    if (details?.aiType === "Text Ai") {
      navigate("/text-ai", {
        state: {
          aiType: details?.aiType,
          textAiType: details?.textAiType,
          transactionId: details?.transactionId,
          totalCreditsUsed: details?.totalCreditsUsed,
          dateTime: details?.dateTime,
          response: details?.response,
          requestQues: details?.requestQues,
        },
      });
    } else if (details?.aiType === "Consultant Ai") {
      navigate("/consultant-ai", {
        state: {
          aiType: details?.aiType,
          transactionId: details?.transactionId,
          totalCreditsUsed: details?.totalCreditsUsed,
          dateTime: details?.dateTime,
          usingChat: true,
          messages: details?.companionAi,
          companionAiVoice: details?.companionAiVoice,
          companionAiGender: details?.companionAiGender,
        },
      });
    } else if (details?.aiType === "Image Ai") {
      navigate("/image-ai", {
        state: {
          aiType: details?.aiType,
          transactionId: details?.transactionId,
          totalCreditsUsed: details?.totalCreditsUsed,
          dateTime: details?.dateTime,
          response: details?.response,
          requestQues: details?.requestQues,
          imageAiSize: details?.imageAiSize,
          textwithHashTag: details?.textwithHashTag,
        },
      });
    } else if (details?.aiType === "Video Ai") {
      navigate("/generated-video", {
        state: {
          aiType: details?.aiType,
          transactionId: details?.transactionId,
          totalCreditsUsed: details?.totalCreditsUsed,
          dateTime: details?.dateTime,
          videoAi: details?.videoAi[0],
          videoAiPages1: details?.videoAiPages1,
          isVideoExpire: details?.isVideoExpire,
          videoAiPages16: details?.videoAiPages16,
          videoAiPages9: details?.videoAiPages9,
          videoAiDuration: details?.videoAiDuration,
        },
      });
    } else if (details?.aiType === "Post Ai") {
      navigate("/post-ai", {
        state: {
          aiType: details?.aiType,
          postDataType: details?.postDataType,
          transactionId: details?.transactionId,
          totalCreditsUsed: details?.totalCreditsUsed,
          dateTime: details?.dateTime,
          userId: details?.userId,
          preset: details?.preset,
          credit: details?.credit,
          setShow: true,
          ...(details?.postDataType === "postAi-01" && {
            postData1: details?.postData1,
          }),
          ...(details?.postDataType === "postAi-02" && {
            postData2: details?.postData2,
          }),
        },
      });
    }
  };
  console.log("Page Data:::::", paginatedData);
  return (
    <div>
      <PageHeading title={"USAGE"} />
      <div className="container account-container pb-5">
        {isChecking ? (
          <div className="text-center my-5">
            <h4 className="text-light">Checking...</h4>
          </div>
        ) : (
          <>
            <div className="row gx-2 gy-2">
              {transactionDetails?.length === 0 ? (
                <div className="text-center my-5">
                  <h4 className="text-light">No USAGE Found</h4>
                </div>
              ) : (
                <>
                  {paginatedData?.map((details, i) => (
                    <div className="col-lg-4 col-md-6 col-sm-12" key={i}>
                      <div className="card ">
                        <ul className="list-group list-group-flush text-uppercase">
                          <li className="list-group-item">
                            <span style={{ fontWeight: "500" }}>Date:</span>{" "}
                            {moment(details?.createdAt).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )}
                          </li>
                          <li className="list-group-item">
                            <span style={{ fontWeight: "500" }}>
                              Transaction Id:
                            </span>{" "}
                            {details?.transactionId}
                          </li>
                          <li className="list-group-item">
                            <span style={{ fontWeight: "500" }}>
                              Type of request:
                            </span>{" "}
                            {/* {details?.aiType} */}
                            {details?.aiType === "Video Ai"
                              ? details?.videoTitle || "TEXT VIDEO AI"
                              : details?.aiType}
                            {/* {details?.aiType === 'Video Ai' && details?.totalCreditsUsed == 100 && details?.videoTitle == 'News Video Ai' 
                              ? 'News Video Ai' : 'TEXT VIDEO AI'
                              }
                              {details?.aiType === 'Video Ai'
                              ? details?.videoTitle
                              : details?.aiType} */}
                          </li>
                          <li className="list-group-item">
                            <span style={{ fontWeight: "500" }}>
                              Credits used:
                            </span>{" "}
                            {details?.totalCreditsUsed}
                          </li>
                          <li className="list-group-item">
                            <span style={{ fontWeight: "500" }}>Remarks:</span>{" "}
                            {/* image ai  */}
                            {details?.requestQues && (
                              <span>
                                {details?.requestQues?.length > 25 ? (
                                  <span>
                                    {details?.requestQues?.slice(0, 24)}...
                                  </span>
                                ) : (
                                  <span>{details?.requestQues}</span>
                                )}
                              </span>
                            )}
                            {/* PostAI  */}
                            {/* {details?.postData1?.success && (
                              <span>
                                {details?.postData1?.question} -{" "}
                                {details?.postData1?.innerQues}
                              </span>
                            )} */}
                            {details?.postData1?.success && (
                              <span>
                                {(
                                  details?.postData1?.question +
                                  " - " +
                                  details?.postData1?.innerQues
                                ).length > 27
                                  ? (
                                      details?.postData1?.question +
                                      " - " +
                                      details?.postData1?.innerQues
                                    ).substring(0, 25) + "..."
                                  : details?.postData1?.question +
                                    " - " +
                                    details?.postData1?.innerQues}
                              </span>
                            )}
                            {/* {
                              details?.postData2?.success && <span>{details?.preset} - {details?.postData2?.textwithHashTag?.replace("Title: ", "")?.split('#')[0]?.trim()}</span>
                            } */}

                            {
                              details?.postData2?.reply === details?.preset && details?.aiType == 'Post Ai'  && <span>
                                {
                                ('Add Your Script - ' + details?.preset)?.length > 27 ? 
                                ('Add Your Script - ' + details?.preset)?.substring(0,25) + "..." : ('Add Your Script -' + details?.preset)
                                }
                              </span>
                            }

                            {details?.postData2?.reply !== details?.preset && details?.postData2?.success && (
                              <span>
                                {(
                                  details?.preset +
                                  " - " +
                                  details?.postData2?.textwithHashTag?.replace("Title: ", "")?.split("#")[0]?.trim()
                                ).length > 27
                                  ? (
                                      details?.preset +
                                      " - " +
                                      details?.postData2?.textwithHashTag?.replace("Title: ", "")?.split("#")[0]?.trim()
                                    ).substring(0, 25) + "..."
                                  : details?.preset +
                                    " - " +
                                    details?.postData2?.textwithHashTag
                                      ?.replace("Title: ", "")
                                      ?.split("#")[0]
                                      ?.trim()}
                              </span>
                            )}

                            {/* consultant AI  */}
                            {details?.companionAi?.length > 0 && (
                              <span>
                                {details?.companionAi[0]?.userQues?.length >
                                25 ? (
                                  <span>
                                    {details?.companionAi[0]?.userQues?.slice(
                                      0,
                                      24
                                    )}
                                    ...
                                  </span>
                                ) : (
                                  <span>
                                    {details?.companionAi[0]?.userQues}
                                  </span>
                                )}
                              </span>
                            )}
                            {details?.remarks && (
                              <span>
                                {details?.remarks?.length > 25 ? (
                                  <span>
                                    {details?.remarks?.slice(0, 24)}...
                                  </span>
                                ) : (
                                  <span>{details?.remarks}</span>
                                )}
                              </span>
                            )}
                          </li>
                          <li className="list-group-item text-center">
                            <button
                              className="view-button w-100"
                              // onClick={() => handleClickOpen(details)}
                              onClick={() => handleRedirectToView(details)}
                            >
                              VIEW{" "}
                              <FaEye
                                className=""
                                style={{ fontSize: 16, marginTop: "-2px" }}
                              />
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            <div className="d-flex justify-content-center text-light mt-3">
              <Pagination
                shape="rounded"
                count={Math.ceil(transactionDetails?.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Transaction;
