import React, { useContext, useEffect, useState } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import moneyExchangeIcon from "../../Assets/Images/transaction-data.png";
import muteIcon from "../../Assets/Images/mute-icon.png";
import "./CompanionAi.css";
import arrowIcon from "../../Assets/Images/arrow-icon.png";
import { Modal, Button, Tooltip, Form, Select, Input, Alert } from "antd";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import CompanionVoiceSelect from "./CompanionVoiceSelect";
import { voices } from "../VideoAi/api";
import { Howl } from "howler";
import { useRef } from "react";
import { GoEye, GoEyeClosed, GoMute, GoUnmute } from "react-icons/go";
import { IoMdSend } from "react-icons/io";
import ReactMarkdown from "react-markdown";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import Avatar from "../Gaming/Avatar";
import {
  handleAiChatCompletions,
  handleAiChatCompletionsVideo,
  handleAiChatCompletionsAssistant,
} from "../../utils/AiApiFunctions";
import reload from "../../Assets/Images/relaoder.png";

const loadingMessages = [
  "Hang tight while we load the awesomeness!",
  "Loading the magic, please wait…",
  "Gathering pixels and data, almost there…",
  "Loading progress bar: Patience is a virtue!",
  "Fetching bits and bytes, just a moment…",
  "Loading brilliance, it’s worth the wait!",
  "One step closer to greatness, loading…",
  "Loading… Grab a snack, we’ll be quick!",
  "Loading the future, stay tuned…",
  "Loading… Meanwhile, enjoy the anticipation!",
  "We’re on it! Loading in progress…",
  "Loading the good stuff, hang in there!",
  "Loading the next big thing, just for you!",
  "Loading… Because good things take time!",
  "Building the excitement, loading…",
  "Loading… Let’s make patience a habit!",
  "Loading… The journey to excellence begins!",
  "Loading… Slow and steady wins the race!",
  "Brace yourself, loading brilliance ahead!",
  "Loading… Your patience is highly appreciated!",
];

// const questionsAsk = [
//   "What social media platforms should my business be on?",
//   "How can I increase engagement on my social media posts?",
//   "What types of content perform best on each platform?",
//   "How often should I post on social media?",
//   "Should I use paid advertising on social media?",
//   "How can I grow my following on social media?",
//   "What are the best practices for creating visually appealing posts?",
//   "How can I leverage user-generated content on social media?",
//   "What metrics should I track to measure the success of my social media efforts?",
//   "How can I use social media to drive traffic to my website?",
//   "What are some effective ways to engage with my audience on social media?",
//   "How can I use storytelling to connect with my followers on social media?",
//   "What are the latest trends in social media marketing?",
//   "How can I use hashtags effectively on social media?",
//   "What are some common mistakes businesses make on social media?",
//   "How can I handle negative feedback or comments on social media?",
//   "Should I use influencers to promote my brand on social media?",
//   "How can I create a cohesive brand identity across all my social media channels?",
//   "What are some creative ways to showcase my products or services on social media?",
//   "How can I use social media to generate leads for my business?",
//   "What are the benefits of using video content on social media?",
//   "How can I use social media to stay connected with my existing customers?",
//   "What are some effective strategies for running social media contests or giveaways?",
//   "How can I optimize my social media profiles for better visibility?",
//   "What are the best times to post on social media for maximum engagement?",
//   "How can I use social media to build relationships with other businesses or influencers?",
//   "What are some ways to repurpose content for different social media platforms?",
//   "How can I use social listening to understand my audience better?",
//   "Should I invest more in organic reach or paid advertising on social media?",
//   "What are some tools or apps that can help me manage my social media accounts more efficiently?",
//   "How can I use social media to showcase the human side of my brand?",
//   "What are some strategies for creating shareable content on social media?",
//   "How can I measure the ROI of my social media marketing efforts?",
//   "What are the differences between B2B and B2C social media marketing?",
//   "How can I use Facebook Insights to analyze the performance of my page?",
//   "What are some ways to integrate social media into my overall marketing strategy?",
//   "How can I use LinkedIn to connect with potential clients or partners?",
//   "What are some emerging social media platforms that businesses should keep an eye on?",
//   "How can I use Instagram Stories to engage with my audience?",
//   "What are some strategies for building a loyal community on social media?",
//   "How can I use Pinterest to drive traffic to my website or blog?",
//   "What are some ways to encourage user-generated content on social media?",
//   "How can I use Twitter to participate in relevant conversations in my industry?",
//   "What are some strategies for creating effective Facebook ads?",
//   "How can I use Instagram Reels to showcase my products or services?",
//   "What are some best practices for responding to messages or comments on social media?",
//   "How can I use social media to promote events or special promotions?",
//   "What are some strategies for increasing reach and engagement on LinkedIn?",
//   "How can I use social media to establish myself or my business as a thought leader in my industry?",
//   "What are some ways to use LinkedIn Groups to connect with other professionals?",
//   "How can I use social media to conduct market research or gather customer feedback?",
//   "What are some strategies for leveraging user-generated content in my marketing campaigns?",
//   "How can I use Instagram Insights to understand my audience better?",
//   "What are some ways to create interactive content on social media?",
//   "How can I use social media to drive sales for my ecommerce business?",
//   "What are some strategies for building brand loyalty through social media?",
//   "How can I use Facebook Groups to engage with my audience?",
//   "What are some common myths or misconceptions about social media marketing?",
//   "How can I use Instagram Live to connect with my audience in real-time?",
//   "What are some strategies for maximizing the reach of my social media posts?",
//   "How can I use social media to recruit top talent for my company?",
//   "What are some strategies for running successful influencer marketing campaigns?",
//   "How can I use Facebook Messenger to provide customer support?",
//   "What are some ways to use social media to showcase behind-the-scenes content?",
//   "How can I use social media to build trust and credibility with my audience?",
//   "What are some strategies for creating compelling visual content on social media?",
//   "How can I use LinkedIn Ads to target specific demographics or industries?",
//   "What are some ways to measure the effectiveness of my Instagram Stories?",
//   "How can I use social media to promote my blog or content marketing efforts?",
//   "What are some strategies for leveraging user testimonials or reviews on social media?",
//   "How can I use social media to amplify the reach of my email marketing campaigns?",
//   "What are some strategies for creating viral content on social media?",
//   "How can I use Facebook Pixel to track conversions from my ads?",
//   "What are some ways to use social media to educate my audience about my products or services?",
//   "How can I use LinkedIn Publishing to share thought leadership content?",
//   "What are some strategies for optimizing my LinkedIn profile for better visibility?",
//   "How can I use Instagram Ads to reach new audiences?",
//   "What are some ways to use social media to support my PR efforts?",
//   "How can I use social media to build partnerships or collaborations with other businesses?",
//   "What are some strategies for leveraging user-generated content in my advertising campaigns?",
//   "How can I use social media to drive foot traffic to my brick-and-mortar store?",
//   "What are some ways to use Instagram Shopping to sell products directly through the platform?",
//   "How can I use social media to recruit brand ambassadors or advocates?",
//   "What are some strategies for creating engaging Instagram carousels?",
//   "How can I use social media to showcase customer success stories or case studies?",
//   "What are some ways to use LinkedIn Events to promote my company's events?",
//   "How can I use social media to support my crisis management efforts?",
//   "What are some strategies for creating effective LinkedIn Sponsored Content?",
//   "How can I use Instagram Guides to curate and share helpful content with my audience?",
//   "What are some ways to use social media to build anticipation for product launches or announcements?",
//   "How can I use social media to target different demographics or personas within my audience?",
//   "What are some strategies for creating engaging Instagram polls or quizzes?",
//   "How can I use social media to foster community among my customers or followers?",
//   "What are some ways to use LinkedIn Analytics to track the performance of my content?",
//   "How can I use social media to highlight my company's corporate social responsibility efforts?",
//   "What are some strategies for running successful Twitter chats or Q&A sessions?",
//   "How can I use social media to support my employee advocacy program?",
//   "What are some ways to use Instagram Insights to inform my content strategy?"
// ];

// const questionsAsk=[
//   "How did Dunkin' Donuts utilize Facebook Live for marketing?",
//   "What type of content format does Tough Mudder use on Facebook Live?",
//   "How does Benefit Cosmetics engage with its audience on Facebook Live?",
//   "What is the significance of combining Live videos with podcasting?",
//   "How does Chubbies interact with its audience during podcasts on Facebook Live?",
//   "What considerations are essential in setting up for a Facebook Live video?",
//   "Why is video a powerful medium for engaging audiences on social media platforms like Facebook?",
//   "What are the benefits of using LinkedIn for business and marketing?",
//   "How can companies repurpose existing content effectively on LinkedIn Company Pages?",
//   "In what ways can images enhance engagement on LinkedIn posts?",
//   "What is the impact of using videos on engagement levels on LinkedIn?",
//   "How can LinkedIn's Content Suggestions tool benefit businesses in content creation?",
//   "What is the importance of posting original content on LinkedIn Company Pages?",
//   "How can companies improve their search engine ranking on LinkedIn?",
//   "What is the significance of a fully completed LinkedIn Company Page?",
//   "How frequently should companies post on their LinkedIn Company Page for optimal engagement?",
//   "How can Showcase Pages on LinkedIn complement a Company Page?",
//   "What are the key differences between Company Pages and Showcase Pages on LinkedIn?",
//   "How can LinkedIn Company Pages be optimized for visibility and searchability?",
//   "Why is it important to have a clear purpose for a LinkedIn Company Page?",
//   "What criteria must be met to set up a LinkedIn Company Page?",
//   "How can LinkedIn Company Pages be structured and optimized for marketing goals?",
//   "What are some best practices for filling out a LinkedIn Company Page for improved visibility?",
//   "How does the completeness of a LinkedIn Company Page impact views and connections?",
//   "What are the steps involved in setting up a LinkedIn Company Page effectively?",
//   "Why is it crucial to have a comprehensive plan before creating Facebook Live videos?",
//   "How can Facebook Live videos enhance audience engagement compared to recorded videos?",
//   "Which brands have effectively used Facebook Live for Q&A sessions?",
//   "How can Facebook Live be utilized as a platform for conducting interviews?",
//   "What types of videos have high engagement rates on social media platforms like Facebook?",
//   "How can podcasting be integrated with Live videos for enhanced engagement?",
//   "What are the benefits of hosting a live Q&A show on Facebook Live?",
//   "How can companies leverage behind-the-scenes content for marketing on Facebook Live?",
//   "In what ways can makeup tutorials drive engagement on Facebook Live?",
//   "How can product tutorials be effectively presented through live videos?",
//   "What are the advantages of hosting live interviews on social media platforms like Facebook?",
//   "How can businesses capitalize on the interactive features of Facebook Live for marketing purposes?",
//   "What strategies can companies use to interact with their audience during and after live podcast sessions?",
//   "What role does original and relevant content play in search engine optimization for LinkedIn Company Pages?",
//   "Why is it important to include high-quality images in LinkedIn posts for organizations?",
//   "How can LinkedIn's Content Suggestions tool aid in content creation for different industries?",
//   "How can LinkedIn's Publishing Platform be used to engage a wider audience?",
//   "What are some key considerations for sharing content and engaging with followers on LinkedIn for business?",
//   "How can businesses determine the purpose and target audience for their LinkedIn Page?",
//   "What strategies can help boost SEO for LinkedIn Company Pages and improve visibility on search engines?",
//   "Why is it crucial to have a keyword-rich profile on LinkedIn for improved visibility?",
//   "What are some ways to include backlinks effectively on LinkedIn Company Pages?",
//   "How can thought leadership posts contribute to audience engagement on LinkedIn?",
//   "What are the essential components of a fully completed LinkedIn Company Page for optimal performance?",
//   "How can participation in LinkedIn groups enhance a company's presence and visibility on the platform?",
//   "What role do images play in boosting engagement on LinkedIn Company Pages?",
//   "Why is it important to establish a recognizable posting cadence on LinkedIn Company Pages?",
//   "How can posting at different times of the day help in identifying peak engagement periods on LinkedIn?",
//   "What are some best practices for creating and maintaining Showcase Pages on LinkedIn?",
//   "What distinguishes Showcase Pages from Company Pages on LinkedIn in terms of layout and features?",
//   "How can Showcase Pages cater to specific marketing personas on LinkedIn?",
//   "What are some differences between Company Pages and Showcase Pages on LinkedIn in terms of layout and functionality?",
//   "How can LinkedIn Company Pages be optimized for improved searchability and visibility?",
//   "Why is consistency in posting essential for fostering engagement on LinkedIn Company Pages?",
//   "How can reposting best-performing content benefit a company's LinkedIn presence?",
//   "How does LinkedIn's Content Suggestions tool help in discovering trending topics for content creation?",
//   "How can content suggestions from LinkedIn be utilized to generate engaging content for the audience?",
//   "What are some key considerations when sharing company culture and product updates on LinkedIn Company Pages?",
//   "How can companies tailor their content on LinkedIn to address the needs of their target audience effectively?",
//   "What are the key elements of on-page optimization for LinkedIn Company Pages to improve search engine ranking?",
//   "Why is it essential to have a complete and detailed profile on LinkedIn for improved discoverability?",
//   "How can a fully completed LinkedIn Company Page enhance visibility and engagement on the platform?",
//   "What are the benefits of setting up a LinkedIn Company Page for businesses?",
//   "How can LinkedIn Company Pages be optimized for better search engine performance?",
//   "Why is it important to engage with followers and share original content on LinkedIn Company Pages?",
//   "How can LinkedIn's Content Suggestions tool assist in finding relevant topics for posting on Company Pages?",
//   "What is the significance of establishing a clear purpose and target demographic for a LinkedIn Page?",
//   "How can companies utilize LinkedIn for industry news, company culture, and educational resources effectively?",
//   "What strategies can businesses implement to improve their search engine rank on LinkedIn?",
//   "Why is it crucial to have high-quality images and updated information on LinkedIn Company Pages?",
//   "How can LinkedIn Company Pages contribute to boosting SEO and visibility for a business?",
//   "What role does consistent posting play in maintaining engagement on LinkedIn Company Pages?",
//   "How can LinkedIn Showcase Pages enhance the user experience and engagement on the platform?",
//   "What are the key differences between Company Pages and Showcase Pages on LinkedIn in terms of layout and features?",
//   "How can Showcase Pages be used to promote specific products or cater to distinct marketing personas?",
//   "Why is it important to optimize all fields on a LinkedIn Company Page for better searchability?",
//   "What benefits can businesses gain from reposting their best-performing content on LinkedIn?",
//   "How can LinkedIn's Content Suggestions tool help companies discover trending topics for content creation?",
//   "What are the key elements of a well-optimized LinkedIn Company Page for improved visibility?",
//   "How can fully completing a LinkedIn Company Page impact views and connections on the platform?",
//   "What are the prerequisites for setting up a LinkedIn Company Page successfully?",
//   "How can businesses structure and optimize their LinkedIn Company Pages for marketing goals effectively?",
//   "Why is it essential to have a comprehensive plan before creating Facebook Live videos for marketing purposes?",
//   "How do videos on Facebook Live facilitate better engagement with audiences compared to recorded videos?",
//   "Which brands have effectively utilized Facebook Live for conducting interviews and Q&A sessions?",
//   "What types of content formats tend to have high engagement rates on social media platforms like Facebook?",
//   "How can makeup tutorials drive audience engagement on platforms like Facebook Live?",
//   "What strategies can be implemented to enhance audience interaction during live videos on social media platforms?",
//   "Why is it crucial for businesses to have a clear purpose and target demographic in mind before creating content for social media platforms?",
//   "How can companies effectively set up and optimize their LinkedIn Company Pages for better visibility and engagement?",
//   "What are the key factors to consider when planning and executing Facebook Live videos for marketing purposes?",
//   "How can businesses leverage interactive features on social media platforms like Facebook Live for audience engagement?",
//   "What role does original and relevant content play in driving engagement on social media platforms?",
//   "How can businesses utilize LinkedIn for industry news, company culture, and educational resources effectively?",
//   "Why is it important to repurpose existing content for strategic posting on social media platforms?"
// ];
const questionsAsk = [
  "What is digital marketing?",
  "How is digital marketing similar to traditional marketing?",
  "Why is an online presence necessary for businesses today?",
  "What are the benefits of having a strong digital presence?",
  "Why is it important to stay updated with digital marketing trends?",
  "What are the core disciplines of digital marketing?",
  "What is the Customer Value Journey?",
  "How does the Customer Value Journey help in business growth?",
  "What are the 8 stages of the Customer Value Journey?",
  "Why is understanding the Customer Value Journey crucial for marketers?",
  "What is content marketing?",
  "How can content marketing attract prospects?",
  "What types of content can be used at the top of the funnel?",
  "What is the role of content marketing in the evaluation stage of the funnel?",
  "What content types are suitable for converting leads into customers?",
  "How does content marketing support the sales process?",
  "What is meant by 'perfect' content?",
  "How does content marketing fit into the overall digital marketing strategy?",
  "What is a Lead Magnet?",
  "What is the Content Lifecycle?",
  "What is digital advertising?",
  "How does paid traffic differ from organic traffic?",
  "What are the top three sources of paid traffic?",
  "How can businesses control paid traffic?",
  "What are the advantages of using paid traffic?",
  "How can paid traffic complement organic traffic?",
  "What is the difference between Google and Facebook ads?",
  "What makes YouTube ads unique?",
  "How should ads be targeted based on the customer journey?",
  "What are the five key elements of a high-performing ad campaign?",
  "What is social media marketing?",
  "How does the Social Success Cycle work?",
  "How can businesses attract fans and followers through social media?",
  "What strategies are effective for engaging social media audiences?",
  "How can social media be used to drive sales?",
  "What role does content play in social media marketing?",
  "How important is community management in social media marketing?",
  "What are some best practices for social media marketing?",
  "How can businesses measure social media marketing success?",
  "What are the challenges of social media marketing?",
  "Why is email marketing still relevant today?",
  "How can email marketing help businesses grow?",
  "What are some best practices for email marketing?",
  "How can email marketing be used to guide customers through the Customer Value Journey?",
  "What metrics should be tracked in email marketing?",
  "What types of emails are effective for engaging subscribers?",
  "How can businesses improve their email open rates?",
  "What role does personalization play in email marketing?",
  "How can email marketing support other digital marketing efforts?",
  "What are the common pitfalls in email marketing?",
  "What is search marketing?",
  "How has search marketing changed in recent years?",
  "What are the key components of search engine optimization (SEO)?",
  "How can search marketing drive website traffic?",
  "What strategies can improve a website's search engine ranking?",
  "What is the role of keywords in search marketing?",
  "How does SEO support other digital marketing disciplines?",
  "What tools can help with search marketing?",
  "How can businesses measure the success of their search marketing efforts?",
  "What are common SEO mistakes to avoid?",
  "What is website analytics?",
  "Why are website analytics important for digital marketers?",
  "How can analytics help identify what's working and what's not?",
  "What are the key metrics to track in website analytics?",
  "How can businesses use analytics to improve their marketing strategies?",
  "What tools are available for website analytics?",
  "How can website analytics guide decision-making in digital marketing?",
  "What are the challenges of using website analytics?",
  "How can analytics help in understanding customer behavior?",
  "What role does data play in digital marketing?",
  "What is conversion rate optimization (CRO)?",
  "Why is CRO critical to digital marketing success?",
  "What are the key steps in the CRO process?",
  "How can CRO improve marketing campaign performance?",
  "What are common techniques for optimizing conversion rates?",
  "How can A/B testing be used in CRO?",
  "What metrics are important in CRO?",
  "How can CRO be integrated into the overall digital marketing strategy?",
  "What are the challenges of implementing CRO?",
  "How does CRO contribute to business growth?",
  "How do digital marketing strategies differ for B2B and B2C companies?",
  "What role does branding play in digital marketing?",
  "How can digital marketing tactics be aligned with business goals?",
  "What are the best practices for creating a digital marketing strategy?",
  "How does customer feedback influence digital marketing?",
  "What is the importance of having a mobile-friendly digital marketing approach?",
  "How can businesses stay updated with digital marketing trends?",
  "What are the ethical considerations in digital marketing?",
  "How can businesses leverage user-generated content in their digital marketing?",
  "What are some common challenges faced by digital marketers today?",
  "How can businesses create a multi-channel digital marketing strategy?",
  "What is the role of automation in digital marketing?",
  "How can artificial intelligence be used in digital marketing?",
  "What are the key considerations when scaling a digital marketing strategy?",
  "How can businesses optimize their digital marketing budget?",
  "What is the importance of storytelling in digital marketing?",
  "How does influencer marketing fit into digital marketing strategies?",
  "How can businesses use webinars as part of their digital marketing efforts?",
  "What are the future trends in digital marketing?",
  "How can businesses measure the overall ROI of their digital marketing efforts?",
];

const CompanionAi = () => {
  const { AIuser, tokenLocal, userRefetch, setUserRefetch } =
    useContext(UserAuthContext);
  const [generatingtext, setGeneratingText] = useState("");
  const [text, setText] = useState("");
  const loggedinUser = AIuser?.name;
  const [questions, setQuestions] = useState("What is your name?");
  const [userName, setUserName] = useState("");
  const [loadingMsg, setLoadinMsg] = useState("");
  const [chatbotName, setChatbotName] = useState("");
  const [gender, setGender] = useState("Male");
  const [percent, setPercent] = useState(0);
  const [nameSubmitted, setnameSubmitted] = useState(false);
  const [defaultValue, setDefaultValue] = useState("");
  const [voice, setVoice] = useState(defaultValue);
  const [usingChat, setUsingChat] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isRestarted, setIsRestarted] = useState(false);
  const [audioDuration, setAudioDuration] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isShort, setIsShort] = useState(true);
  const [isHaveViewMessage, setIsHaveViewMessage] = useState(false);
  const [randomNumber, setRandomNumber] = useState(
    Math.floor(Math.random() * questionsAsk?.length)
  );
  const [messages, setMessages] = useState([
    { author: chatbotName, body: `Hi ${userName}, How can I help you?` },
  ]);

  useEffect(() => {
    if (AIuser?.name) {
      setUserName(AIuser?.name);
    }
  }, [AIuser]);

  const countWordsResponse = (str) => {
    return str.trim().split(/\s+/).filter(Boolean).length;
  };
  const [width, setWidth] = useState("94%");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setWidth("87%");
      } else {
        setWidth("93%");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set the initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log("cahtbotname =====================: ", userName);
  console.log("cahtbotname =====================: ", loggedinUser);
  const [inputText, setInputText] = useState("");

  const [isGenerating, setIsGenerating] = useState(false);
  const [nextQues, setNextQues] = useState("1st");

  const inputRef = useRef(null);
  const divRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [voiceCount, setVoiceCount] = useState(1);
  const [finalSubmit, setfinalSubmit] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [showAvatar, setshowAvatar] = useState(false);
  const [arrivalText, setarrivalText] = useState(false);
  const [words, setWords] = useState(0);
  const [audiocontent, setAudioContent] = useState(0);
  const [sound, setSound] = useState(null);
  const [viewMessages, setViewMessages] = useState([]);
  const inputRef2 = useRef(null);
  const wordRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const [transactionId, setTransactionId] = useState("");

  useEffect(() => {
    // generate a random 8 character alpha numeric string

    const randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567891011121314156655";
    let result = "";
    for (let i = 0; i < 8; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    setTransactionId(result);
  }, []);

  function formatBoldText(text) {
    return text?.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
  }
  // show the user name in the first question of the chatbot
  const copyMsgs = messages;
  copyMsgs[0].body = `Hi ${userName}, How can I help you?`;

  const handleScroll = (event) => {
    if (event.deltaY > 0) {
      setIsScrolling(false);
    } else if (event.deltaY < 0) {
      setIsScrolling(true);
    }
  };

  useEffect(() => {
    // if the text is generating then run the below code
    if (isGenerating === true) {
      const handleScroll = () => {
        const scrollTop = inputRef?.current?.scrollTop;
        setIsScrollingUp(scrollTop < inputRef?.current?._prevScrollTop);
        inputRef.current._prevScrollTop = scrollTop;
      };

      inputRef.current._prevScrollTop = 0;
      inputRef.current.addEventListener("scroll", handleScroll);
      const ref = inputRef.current;

      return () => {
        if (ref) {
          ref.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [generatingtext, isGenerating]);

  useEffect(() => {
    if (isScrolling === false && isScrollingUp === false) {
      inputRef.current.scrollTop = inputRef.current.scrollHeight;
    }
    console.log(
      isGenerating,
      text,
      isScrolling,
      isScrollingUp,
      generatingtext,
      "all the text",
      text == generatingtext
    );
  }, [text, isScrolling, isScrollingUp]);

  useEffect(() => {
    const worker = new Worker("/worker.js");

    worker.addEventListener("message", (event) => {
      const { text } = event.data;

      // setIsDoneGenerating(isDone);
      if (text !== "Processing...") {
        setText(text);
      }

      setMessages([...messages, { author: chatbotName, body: text }]);
      setInputText("");
      console.log([...messages, { author: chatbotName, body: text }]);
      inputRef.current.focus();
    });

    worker.postMessage({ generatingtext, words });
    return () => {
      worker.terminate();
      // setarrivalText(false)
    };
  }, [generatingtext, isGenerating]);

  const handleSubmitCredits = async (points) => {
    const deductedCredits = AIuser?.credits - points;
    // console.log("question", question, conversation);

    await axios
      .put(
        "https://backend.whalesai.com/api/dashboard/user_signup-signin/update-credits",
        { email: AIuser?.email, credits: deductedCredits },
        {
          headers: {
            authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setUserRefetch(!userRefetch);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsHaveViewMessage(false);
    // setarrivalText(true)

    // if (divRef.current) {
    //   divRef.current.scrollIntoView({ behavior: "smooth" });
    // }
    localStorage.setItem("inputText", inputText);

    if (AIuser.credits > 0) {
      setIsGenerating(true);
      setGeneratingText(() => "");
      setText("");
      setIsScrolling(false);
      setIsScrollingUp(false);
      setMessages([...messages, { author: userName, body: inputText }]);
      console.log(
        "latestttttttttttt msg => :::::::::::::::::::::::::::::::",
        inputText
      );
      const prompt = messages.map((message) => message.body).join("\n");
      let question = `${prompt}\n${userName}: ${inputText}\n${chatbotName}:`;

      const response = await handleAiChatCompletionsAssistant(
        question,
        inputText
      );

      if (response) {
        handleSynthesize(response, voice);
        const points = Math.floor(countWordsResponse(response) / 10);
        handleSubmitCredits(points);
        const transactionRes = await axios.post(
          "https://backend.whalesai.com/api/v1/credits-transaction/create",
          {
            aiType: "Consultant Ai",
            transactionId: transactionId,
            requestQues: inputText,
            response: formatBoldText(response),
            credit: points,
            userId: AIuser?._id,
            userName: userName,
            companionName: chatbotName,
            companionAiVoice: voice,
            companionAiGender: gender,
            // userId: "6468c8ab7f567716c118ad6c",
          }
        );

        setTimeout(() => {
          console.log(formatBoldText(response), "formatBoldText(response)");
          setGeneratingText(formatBoldText(response));
          setIsGenerating(false);
        }, 1000);
      } else {
        setGeneratingText("Invalid prompt!");
      }

      // await axios
      //   .post(
      //     "https://backend.dsl.sg/api/v1/chatgpt/gpt4",
      //     { question: question, preset: "CHAT" },
      //     {
      //       headers: {
      //         authorization: `Bearer dsltoken`,
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     if (res.status === 200 && res.data?.outputMessage) {
      //       handleSubmitCredits();
      //       setGeneratingText(res.data?.outputMessage);
      //       handleSynthesize(res.data?.outputMessage, voice);
      //       setIsGenerating(false);
      //     } else {
      //       setGeneratingText("Invalid prompt!");
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => { });
    } else {
      setShowAlert(true);
    }
  };

  const handleClearInput = () => {
    document.getElementById("inputText").value = "";
    setInputText("");
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      inputText !== "" &&
      !event.shiftKey &&
      window.innerWidth > 1000
    ) {
      if (divRef.current) {
        divRef.current.scrollIntoView({ behavior: "smooth" });
      }
      event.preventDefault();
      event.stopPropagation();
      handleSubmit(event);
    }

    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      setInputText(inputText + "\n");
    }

    if (window.innerWidth < 1000 && event.key === "Enter") {
      event.preventDefault();
      setInputText(inputText + "\n");
    }
  };

  const onFinish = () => {
    // if (userName && chatbotName && finalSubmit) {
    if (userName && chatbotName) {
      setnameSubmitted(true);
      setUsingChat(true);

      handleSynthesize("Hi " + userName + ", how can I help you?", voice);

      // if (divRef.current) {
      //   divRef.current.scrollIntoView({ behavior: "smooth" });
      // }
      setIsShort(false);
    }
  };

  const handlePercent = (value) => {
    if (value == "1st") {
      setPercent(35);
    } else if (value == "2nd") {
      setPercent(70);
    } else {
      setPercent(100);
      // make showAvatar true after 3seconds
      setTimeout(() => {
        // setShowAvatar(true);
      }, 3000);
    }
  };

  const onChange = (value) => {
    setGender(value);
  };

  const onChangeVoices = (value) => {
    const selectedVoices = voices?.find(
      (voice) => voice.label === value.target.value,
      setChatbotName(value.target.value)
    );
    setVoice(selectedVoices.value);
    // console.log(selectedVoices.value);

    setDefaultValue(selectedVoices);
    const latestInput = localStorage.getItem("latestInput");
    handleSynthesize(latestInput, selectedVoices.value);
    // setGender(value);
  };

  useEffect(() => {
    return () => {
      if (sound) {
        sound.stop();
      }
    };
  }, [sound]);

  const toggleMute = () => {
    setIsMuted((isMuted) => !isMuted);
    const muted = isMuted;
    if (sound) {
      sound.mute(!muted);
    }
  };

  const handleSynthesize = async (input, voiceParam) => {
    try {
      localStorage.setItem("latestInput", input);
      // console.log("voiceParam", voiceParam);
      const splittedVoice = voiceParam?.split("-");

      // setVoice(voices?.[randomIndex]?.value);

      const randomVoice = defaultValue?.value;
      const splittedRandomVoice = randomVoice?.split("-");

      const response = await fetch(
        "https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=AIzaSyBnaoRoWAnWm6poRcPrETHcYF0LZryXzw8",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input: {
              text: input,
            },

            voice: {
              languageCode: voiceParam
                ? `${splittedVoice?.[0]}-${splittedVoice?.[1]}`
                : `${splittedRandomVoice?.[0]}-${splittedRandomVoice?.[1]}`,
              name: voiceParam ? voiceParam : randomVoice,
            },

            audioConfig: {
              audioEncoding: "MP3",
            },
          }),
        }
      );

      const data = await response.json();
      const audioContent = data.audioContent;

      setAudioContent(audioContent);

      // create a new Howler.js sound object
      const sound = new Howl({
        src: [`data:audio/mp3;base64,${audioContent}`],
        mute: isMuted,
        onload: function () {
          const duration = sound.duration();
          setAudioDuration(duration);
        },
        onplay: function () {
          setIsPlaying(true);
        },
        onend: function () {
          setIsPlaying(false);
        },
      });

      // play the sound automatically
      sound.play();
      setSound(sound);
      setIsPlaying(true); // set playing state to true initially
    } catch (err) {
      console.log(err);
    }
  };

  const randomIndex = Math.floor(Math.random() * voices?.length);

  useEffect(() => {
    // scroll to top on initial render
    window.scrollTo(0, 0);
    setDefaultValue(voices?.[randomIndex]);
    setChatbotName(voices?.[randomIndex]?.value);
    setVoice(voices?.[randomIndex]?.value);
  }, []);

  useEffect(() => {
    let i = 0;
    let textQ;
    console.log("is loaded===========================> ::", loaded);
    if (nextQues == "1st") {
      // if (!loaded) {
      //   textQ = loadingMessages[Math.floor(Math.random() * loadingMessages?.length)];
      //   setLoadinMsg(textQ)
      // } else {
      textQ = "What is your name?";
      handleSynthesize("What is your name?", voice);
      // }
      // if (isRestarted === false) {
      //   // if (loaded) {
      //     handleSynthesize("What is your name?", voice);
      //   // }
      // }
    } else if (nextQues == "2nd") {
      textQ = "Which consultant would you like to chat with?";
      // if (isRestarted === false) {
      handleSynthesize("Which consultant would you like to chat with?", voice);
      // }
    }

    // else {
    //   textQ = "What is your gender?";
    //   if (isRestarted === false) {
    //     handleSynthesize("What is your gender?", voice);
    //   }
    // }
    const interval = setInterval(() => {
      setQuestions(textQ?.slice(0, i));
      i++;
      if (i > textQ?.length) {
        clearInterval(interval);
      }
    }, 80);

    return () => clearInterval(interval);
  }, [nextQues, loaded, voiceCount, voice]);

  const creditTolcalString = AIuser?.credits
    ? parseInt(Math.floor(AIuser?.credits)).toLocaleString()
    : "0";

  // console.log("messages", messages);

  useEffect(() => {
    if (location?.state !== null) {
      setIsHaveViewMessage(true);
      setUsingChat(location.state.usingChat);
      setVoice(location.state.companionAiVoice);
      setGender(location.state.companionAiGender);
      setViewMessages(location.state.messages);
      const selectedVoices = voices?.find(
        (voice) => voice?.value === location?.state?.companionAiVoice
      );
      setDefaultValue(selectedVoices);
    }
  }, [location.state]);

  // console.log('locationnnnnnnnnnnnnnnnnnnn : ' + location.pathname)

  if (location.pathname == "/consultant-ai-app") {
    console.log("its from mobile application..........");
  } else if (location.pathname == "/consultant-ai") {
    console.log("its from web application...........");
  }

  // Function to scroll textarea to bottom

  useEffect(() => {
    // Scroll to the bottom of the chat container when messages change
    divRef.current.scrollTop = divRef.current.scrollHeight;
  }, [messages]);

  console.log("Total ===> ", inputText?.length);

  const nothing = (e) => {
    e.preventDefault();
  }

  return (
    <div className="h-100 companion-ai-container">
      {location?.pathname == "/consultant-ai" && (
        <PageHeading
          title={"CONSULTANT AI"}
          description={
            "The Consultant AI is designed to assist with your social media marketing efforts."
          }
        />
      )}
      <div
        className="companion-ai-main"
        style={{
          maxWidth: "872px",
          paddingInline: "12px",
          marginInline: "auto",
        }}
      >
        <div className="CreditDiv">
          <button className="credits-button">
            CREDITS : {creditTolcalString}
          </button>
          {location?.pathname == "/consultant-ai-app" ? (
            <button
              className="buy-credits-button"
              onClick={() => navigate(`/purchase-app/${tokenLocal}`)}
            >
              BUY CREDITS
            </button>
          ) : (
            <button
              className="buy-credits-button"
              onClick={() => navigate("/purchase")}
            >
              BUY CREDITS
            </button>
          )}
          {location.pathname == "/consultant-ai-app" ? (
            <button
              onClick={() => navigate("/transaction-app")}
              className="money-exchange-button"
            >
              <img src={moneyExchangeIcon} alt="image" />
            </button>
          ) : (
            <button
              onClick={() => navigate("/usage")}
              className="money-exchange-button"
            >
              <img src={moneyExchangeIcon} alt="image" />
            </button>
          )}
        </div>
        <p style={{fontSize: '8px'}} className="text-white mb-0 mt-2 text-center">
          1 Credit per 10 words
          {/* Every request is 1 credit. */}
        </p>
        <div className="selectDiv mt-4 d-flex align-items-center justify-content-between">
          <p
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/do-it-yourself")}
            className="text-warning m-0"
          >
            Click here to generate contents
          </p>
          {/* <CompanionVoiceSelect
            onChangeVoices={onChangeVoices}
            defaultValue={defaultValue}
            setDefaultValue={setDefaultValue}
            setVoice={setVoice}
            voices={voices}
          /> */}
          {/* <div>
          {showAvatar?<Tooltip placement="top" title={"Show"}>
             <GoEye
                className="chatai-icon-styles text-light"
                style={{
                  marginLeft: "5px",
                  fontSize: "28px",

                  cursor: "pointer",
                }}
                onClick={()=>setshowAvatar(false)}
                
              />
            </Tooltip>:<Tooltip placement="top" title={"Hide"}>
             <GoEyeClosed
                className="chatai-icon-styles text-light"
                style={{
                  marginLeft: "5px",
                  fontSize: "28px",

                  cursor: "pointer",
                }}
                onClick={()=>setshowAvatar(true)}
              />
            </Tooltip>}
        
      </div> */}
          {isMuted ? (
            <Tooltip placement="top" title={"Mute"}>
              <GoMute
                className="chatai-icon-styles text-light"
                style={{
                  marginLeft: "5px",
                  fontSize: "28px",

                  cursor: "pointer",
                }}
                onClick={toggleMute}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="top" title={"Unmute"}>
              <GoUnmute
                className="chatai-icon-styles text-light"
                style={{
                  marginLeft: "5px",

                  fontSize: "28px",
                  cursor: "pointer",
                }}
                onClick={toggleMute}
              />
            </Tooltip>
          )}
          {/* <img
            className="mute-icon"
            src={muteIcon}
            alt="image"
            // onClick={handlePlayButton}
          /> */}
        </div>
        {/* {<div className={`${showAvatar==false?"d-none":"d-block"}`}>
         <Avatar
            string={gender}
            generatingtext={generatingtext}
            defaultValue={defaultValue}
            voice={voice}
            voices={voices}
            isPlaying={isPlaying}
            loaded={loaded}
            setLoaded={setLoaded}
            isShort={isShort}></Avatar>
        </div>} */}
        <div
          className="first-field-container"
          // ref={inputRef}
          // // onScroll={handleScroll2}
          // onWheel={handleScroll}
        >
          <div
            className="companion-res-area p-3"
            ref={divRef}
            style={{ overflowY: "auto", maxHeight: "400px" }}
          >
            <div
              className=""
              ref={inputRef}
              // onScroll={handleScroll2}
              onWheel={handleScroll}
            >
              <div class="media mb-3">
                <>
                  <div class="media-body ml-3">
                    <div
                      className="text-light rounded  text-start ask-ai-fonts copy-contents"
                      ref={wordRef}
                    >
                      {showAlert == false ? (
                        <>
                          {usingChat ? (
                            <>
                              {isHaveViewMessage ? (
                                <>
                                  {viewMessages?.map((message, i) => {
                                    return (
                                      console.log("viewMessages", message),
                                      (
                                        <div className="mb-4" key={i}>
                                          <div
                                            style={{
                                              color: "black",
                                            }}
                                          >
                                            <span
                                              className="chat-author text-uppercase"
                                              style={{
                                                fontWeight: "bold",
                                                color: "#a500b7",
                                              }}
                                            >
                                              {message?.userName}:
                                            </span>{" "}
                                            {message.userQues}
                                          </div>
                                          <div
                                            className="mb-4"
                                            style={{
                                              color: "black",
                                              whiteSpace: "pre-line",
                                            }}
                                          >
                                            <span
                                              className="chat-author text-uppercase"
                                              style={{
                                                fontWeight: "bold",
                                                color: "#003991",
                                              }}
                                            >
                                              {message?.companionName}:
                                            </span>{" "}
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html: formatBoldText(
                                                  message.companionAns
                                                ),
                                              }}
                                            ></p>
                                            {/* <p>{message.companionAns}</p> */}
                                          </div>
                                        </div>
                                      )
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  {messages?.map((message, i) => {
                                    const isHuman = message?.author == userName;
                                    console.log(message?.body, "amar message");
                                    return (
                                      <div key={i}>
                                        {isHuman ? (
                                          <div
                                            className="mb-4"
                                            style={{
                                              color: "black",
                                            }}
                                          >
                                            <span
                                              className="chat-author text-uppercase"
                                              style={{
                                                fontWeight: "bold",
                                                color: "#003991",
                                              }}
                                            >
                                              {userName}:
                                            </span>{" "}
                                            {message?.body}
                                            {isGenerating && (
                                              <span className="mb-4 d-block text-to-animate-constant">
                                                Your consultant is thinking ….
                                              </span>
                                            )}
                                          </div>
                                        ) : (
                                          <>
                                            {message?.body && (
                                              <div
                                                className="mb-4"
                                                style={{
                                                  color: "black",
                                                  whiteSpace: "pre-line",
                                                }}
                                              >
                                                <span
                                                  className="chat-author text-uppercase textContainer"
                                                  style={{
                                                    color: "#003991",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {chatbotName}:
                                                </span>{" "}
                                                <span
                                                  style={{
                                                    color: "black",
                                                    whiteSpace: "pre-line",
                                                  }}
                                                  dangerouslySetInnerHTML={{
                                                    __html: formatBoldText(
                                                      message.body
                                                    ),
                                                  }}
                                                ></span>
                                                {/* {message.body} */}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <p class="mb-0 text-dark">
                                {/* {questions} */}
                                {
                                  //       !loaded ? <p>{loadingMsg}</p>
                                  // :
                                  <ReactMarkdown>{questions}</ReactMarkdown>
                                }

                                {/* What is your name?
                              <br />
                              Whom you want to chat with? */}
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Alert
                            message="You don't enough credits! Please buy credits to continue."
                            type="error"
                            showIcon
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {/* ) : (
                  <div className="text-center mx-auto">
                    {" "}
                    <PropagateLoader
                      color="white"
                      loading
                      size={window.innerWidth <= 700 ? 10 : 15}
                    />
                  </div>
                )} */}
                </>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
        {nameSubmitted ? (
          <>
            <div className="second-field-container position-relative">
              <form className="text-ai-rep-form form " >
                <textarea
                  style={{ width: width }}
                  className="second-text-area"
                  type="text"
                  lang="en"
                  placeholder=""
                  value={inputText}
                  id="inputText"
                  name="inputBox"
                  onKeyDown={handleKeyDown}
                  ref={inputRef2}
                  onChange={(e) => {
                    setInputText(e.target.value);
                    setUsingChat(true);
                    setText("");
                  }}
                ></textarea>
                {/* <button
                  className="arrow-button send h-70"
                  onClick={handleSubmit}
                  disabled={inputText?.length === 0}
                  >
                  <img src={arrowIcon} alt="image" />
                </button> */}
                <button
                  className="arrow-button send h-70"
                  onClick={ inputText?.length != 0 ? handleSubmit : nothing}
                  disabled={false} // Ensure the button is always enabled
                  style={{
                    cursor: inputText?.length === 0 ? "not-allowed" : "pointer", // Cursor behavior
                  }}
                >
                  <img
                    src={arrowIcon}
                    alt="image"
                    // red 
                    // style={{
                    //   filter: inputText?.length === 0 ? "grayscale(100%) brightness(0) saturate(100%) hue-rotate(0deg) invert(37%) sepia(94%) saturate(6515%) hue-rotate(350deg) brightness(92%) contrast(101%)" : "none", // Red color when input is empty
                    // }}
                    style={{
                      filter: inputText?.length === 0
                        ? "invert(66%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(90%) contrast(86%)" // Applying #A8A8A8 color when input is empty
                        : "none", // Normal color when input is not empty
                    }}
                  />
                </button>
              </form>
            </div>

            <div className="text-center">
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (text == generatingtext || text == "") {
                    if (!isGenerating) {
                      // if (!isGenerating) {
                      setInputText(questionsAsk[randomNumber]);
                      setUsingChat(true);
                      setText("");
                      let number = Math.floor(
                        Math.random() * questionsAsk?.length
                      );
                      if (number == randomNumber) {
                        number = Math.floor(
                          Math.random() * questionsAsk?.length
                        );
                      }
                      setRandomNumber(number);
                      //  }
                    }
                  }
                }}
                className="text-white text-center"
              >
                <span className="text-warning ">You can ask: </span>
                {questionsAsk[randomNumber]}
              </p>

              <img
                onClick={() => {
                  let number = Math.floor(Math.random() * questionsAsk?.length);
                  if (number == randomNumber) {
                    number = Math.floor(Math.random() * questionsAsk?.length);
                  }
                  setRandomNumber(number);
                }}
                style={{ cursor: "pointer" }}
                src={reload}
                alt="reload"
                width="30"
                height="30"
                className="mb-0"
              />
            </div>
            <div
              style={{ marginBottom: "10px", marginTop: "10px" }}
              className="ai-clearbtn d-flex align-items-center justify-content-center"
            >
              {/* <button className="credits-button mt-2">
                EVERY REQUEST IS 10 CREDITS
              </button> */}
              {inputText.length > 0 && (
                <button
                  className="comp-clear-button "
                  onClick={handleClearInput}
                >
                  CLEAR
                </button>
              )}
            </div>
          </>
        ) : (
          <div className="d-flex w-100 first-field-container">
            <Form
              name="normal_login"
              className="login-form w-100"
              initialValues={{
                remember: true,
              }}
              layout="vertical"
              // onFinish={onFinish}
            >
              {nextQues == "1st" ? (
                <>
                  <Form.Item
                    className="mb-2"
                    name="userName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Name!",
                      },
                    ]}
                    // label="What is your name?"
                    required
                    // tooltip="This is a required field"
                  >
                    <input
                      // disabled={!loaded}
                      className="text-dark selector-second"
                      style={{ borderRadius: "4px 0 0 4px" }}
                      defaultValue={AIuser?.name}
                      placeholder={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </Form.Item>
                </>
              ) : nextQues == "2nd" ? (
                <Form.Item
                  className="mb-2"
                  name="chatbotName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your chatbot Name!",
                    },
                  ]}
                  // label="Whom you want to chat with?"
                  required
                  // tooltip="This is a required field"
                >
                  {/* <Input
                    placeholder=""
                    onChange={(e) => setChatbotName(e.target.value)}
                  /> */}

                  <CompanionVoiceSelect
                    onChangeVoices={onChangeVoices}
                    // onChange={setChatbotName(onChangeVoices.target.value)}
                    defaultValue={defaultValue}
                    setDefaultValue={setDefaultValue}
                    setVoice={setVoice}
                    voices={voices}
                  />
                </Form.Item>
              ) : nextQues == "3rd" ? (
                <select
                  className="selector-second mb-2 h-70"
                  style={{ borderRadius: "4px 0 0 4px" }}
                  name=""
                  id=""
                  onChange={onChange}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              ) : null}
              {nextQues == "1st" ? (
                <div className="d-flex justify-content-between text-uppercase">
                  <Form.Item>
                    <Button
                      type="warning"
                      // htmlType="submit"
                      className="login-form-button me-2 bg-warning text-uppercase"
                      variant="contained"
                      onClick={() => {
                        setNextQues("1st");
                        setVoiceCount(voiceCount + 1);
                        setPercent(0);
                      }}
                    >
                      Back
                    </Button>
                  </Form.Item>

                  <Form.Item>
                    {/* <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button text-uppercase"
                      variant="contained"
                      onClick={() => {
                        if (userName) {
                          handlePercent("1st");
                          setNextQues("2nd");
                          setVoiceCount(voiceCount + 1);
                        }
                      }}>
                      Next
                    </Button> */}
                  </Form.Item>
                </div>
              ) : nextQues == "2nd" ? (
                <>
                  <div className="d-flex justify-content-between text-uppercase">
                    <Form.Item>
                      <Button
                        type="warning"
                        // htmlType="submit"
                        className="login-form-button me-2 bg-warning text-uppercase"
                        variant="contained"
                        onClick={() => {
                          setNextQues("1st");
                          setVoiceCount(voiceCount + 1);
                          setPercent(0);
                        }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      {/* <Button
                        type="primary"
                        htmlType={"submit"}
                        className="login-form-button text-uppercase"
                        onClick={() => {
                          if (chatbotName) {
                            handlePercent("2nd");
                            setNextQues("3rd");
                            setVoiceCount(voiceCount + 1);
                          }
                        }}>
                        Next
                      </Button> */}
                    </Form.Item>
                  </div>
                </>
              ) : nextQues == "3rd" ? (
                <>
                  <div className="d-flex justify-content-between text-uppercase">
                    <Form.Item>
                      <Button
                        type="warning"
                        // htmlType="submit"
                        className="login-form-button me-2 bg-warning text-uppercase"
                        onClick={() => {
                          setNextQues("2nd");
                          setVoiceCount(voiceCount + 1);
                          setPercent(35);
                        }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      {/* <Button
                        type="primary"
                        // disabled={gender == ""}
                        variant="contained"
                        htmlType={"submit"}
                        className="login-form-button text-uppercase"
                        onClick={() => {
                          setfinalSubmit(true);
                        }}>
                        Submit
                      </Button> */}
                    </Form.Item>
                  </div>
                </>
              ) : null}
            </Form>
            {
              <button
                // style={{ height: "32px" }}
                className="arrow-button arrow"
                onClick={() => {
                  console.log(nextQues, userName, chatbotName);
                  if (nextQues == "1st") {
                    if (userName) {
                      handlePercent("1st");
                      setNextQues("2nd");
                      setVoiceCount(voiceCount + 1);
                    }
                  }
                  if (nextQues == "2nd") {
                    console.log("work");
                    if (chatbotName) {
                      // console.log("work1");
                      // handlePercent("2nd");
                      // setNextQues("3rd");
                      // setVoiceCount(voiceCount + 1);
                      setNextQues("4th");
                      setVoiceCount(voiceCount + 2);
                      setfinalSubmit(true);
                      onFinish();
                    }
                  }
                  if (nextQues == "3rd") {
                    setfinalSubmit(true);
                    onFinish();
                  }
                }}
                // disabled={!loaded}
              >
                <img style={{ maxWidth: "21px" }} src={arrowIcon} alt="image" />
              </button>
            }
          </div>
        )}
      </div>
      <div class="d-flex justify-content-center mt-0 pt-0">
        <Button
          type="primary"
          htmlType={"submit"}
          className="login-form-button text-uppercase mb-2"
          onClick={() => window.location.reload()}
        >
          RE-START
        </Button>
      </div>
      <div className="animate text-md-center text-lg-center  text-center d-flex justify-content-center mb-5 force-small-font">
        Please do not navigate away from the browser.
        <br /> Doing so will stop the process.
      </div>
      <p className="mt-5"></p>
    </div>
  );
};

export default CompanionAi;
